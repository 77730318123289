<style>
.invoice-heading {
  background: #eee;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  margin-top: 10px;
}

.invoice-heading h3 {
  margin: auto;
  padding: 7px;
  font-weight: 600;
  text-align: left;
}

.detail {
  font-weight: 800;
}

.row-data {
  padding: 15px 25px 5px 25px;
}

.text-right {
  text-align: right;
}
</style>
<template>
  <div class="cols-12 col-xl-12" style="margin: 0 auto">
    <button v-if="this.$hasPermission('edit-booking')" type="button" class="btn btn-primary mb-2"
      @click="openUpdateBooking">
      Edit booking
    </button>

    <button v-if="this.$hasPermission('modifying-rates')" type="button" class="btn btn-success ml-3 mb-2"
      v-b-modal.modal-prices>
      Edit Prices
    </button>

    <template v-if="chargePayment.captured === true">
      <span style="float: right;font-weight: bolder;font-size: large;">PAYMENT STATUS: PAID</span>
    </template>

    <b-card>
      <div v-if="booking">
      <!-- Applicant -->
      <template v-if="booking.self_pay">
        <div v-if="booking.self_pay.corporate_account != null">
          <b-row class="invoice-heading text-left">
            <b-col md="12">
              <h3 class="">Applicant</h3>
            </b-col>
          </b-row>

          <b-row class="row-data">
            <b-col md="4">
              <h4 class="detail">Name</h4>
            </b-col>
            <b-col md="8" class="text-left">
              <h4 class="">
                {{ booking.self_pay.corporate_account.amera_user.name }}
              </h4>
            </b-col>
          </b-row>

          <b-row class="row-data">
            <b-col md="4">
              <h4 class="detail">Company Name</h4>
            </b-col>
            <b-col md="8" class="text-left">
              <h4 class="">
                {{ booking.self_pay.corporate_account.company_legal_name }}
              </h4>
            </b-col>
          </b-row>

          <b-row class="row-data">
            <b-col md="4">
              <h4 class="detail">Contact Number</h4>
            </b-col>
            <b-col md="8" class="text-left">
              <h4 class="">
                {{
                  booking.self_pay.corporate_account
                    .corporate_account_personal_info.telephone_number
                }}
              </h4>
            </b-col>
          </b-row>
          <b-row class="row-data">
            <b-col md="4">
              <h4 class="detail">Email</h4>
            </b-col>
            <b-col md="8" class="text-left">
              <h4 class="">
                {{
                  booking.self_pay.corporate_account
                    .corporate_account_personal_info.email
                }}
              </h4>
            </b-col>
          </b-row>
        </div>
      </template>


      <!-- Patient Information -->

      <template v-if="booking.self_pay">
        <b-row class="invoice-heading">
          <b-col md="12">
            <h3 class="detail">Patient Information</h3>
          </b-col>
        </b-row>
        <b-row class="row-data">
          <b-col md="4">
            <h4 class="detail">First Name</h4>
          </b-col>
          <b-col md="8" class="text-left">
            <h4 class="">{{ booking.self_pay.name }}</h4>
          </b-col>
        </b-row>
        <b-row class="row-data">
          <b-col md="4">
            <h4 class="detail">Last Name</h4>
          </b-col>
          <b-col md="8" class="text-left">
            <h4 class="">{{ booking.self_pay.lastname }}</h4>
          </b-col>
        </b-row>
        <b-row class="row-data">
          <b-col md="4">
            <h4 class="detail">Contact Number</h4>
          </b-col>
          <b-col md="8" class="text-left">
            <h4 class="">{{ booking.self_pay.phone_number }}</h4>
          </b-col>
        </b-row>
        <b-row class="row-data">
          <b-col md="4">
            <h4 class="detail">Email</h4>
          </b-col>
          <b-col md="8" class="text-left">
            <h4 class="">{{ booking.self_pay.email }}</h4>
          </b-col>
        </b-row>
        <b-row class="row-data">
          <b-col md="4">
            <h4 class="detail">Birthday</h4>
          </b-col>
          <b-col md="8" class="text-left">
            <h4 class="">{{ convertDate(booking.self_pay.birthday) }}</h4>
          </b-col>
        </b-row>
      </template>


      <!-- Information -->
      <b-row class="invoice-heading">
        <b-col md="12">
          <h3 class="detail">Facility Information</h3>
        </b-col>
      </b-row>

      <b-row class="row-data">
        <b-col md="4">
          <h4 class="detail">Facility Name</h4>
        </b-col>
        <b-col md="8" class="text-left">
          <h4 class="">{{ booking.facility_name }}</h4>
        </b-col>
      </b-row>
      <b-row class="row-data">
        <b-col md="4">
          <h4 class="detail">Doctor's Name</h4>
        </b-col>
        <b-col md="8" class="text-left">
          <h4 class="">{{ booking.doctor_name }}</h4>
        </b-col>
      </b-row>
      <b-row class="row-data">
        <b-col md="4">
          <h4 class="detail">Facility Phone Number</h4>
        </b-col>
        <b-col md="8" class="text-left">
          <h4 class="">{{ booking.facility_phone_number }}</h4>
        </b-col>
      </b-row>

      <!-- preschedule -->
      <b-row class="invoice-heading">
        <b-col md="12">
          <h3 class="detail">Booking details</h3>
        </b-col>
      </b-row>

      <b-row class="row-data">
        <b-col md="4">
          <h4 class="detail">Trip Type</h4>
        </b-col>
        <b-col md="8" class="text-left">
          <h4 class="">{{ booking.trip_type }}</h4>
        </b-col>
      </b-row>

      <b-row class="row-data">
        <b-col md="4">
          <h4 class="detail">Trip Distance(miles)</h4>
        </b-col>
        <b-col md="8" class="text-left">
          <h4 class="">{{ booking.total_miles }}</h4>
        </b-col>
      </b-row>

      <b-row class="row-data">
        <b-col md="4">
          <h4 class="detail">Additional stops</h4>
        </b-col>
        <b-col md="8" class="text-left" v-if="listAdditionalStops.length > 0">
          <span v-for="item in listAdditionalStops" :key="item.id" class="text-left">
            <h4 class="">* {{ parseLocationTo(item.to) }}</h4>
          </span>
        </b-col>
        <b-col md="8" class="text-left" v-if="listAdditionalStops.length == 0">
          <h4 class="">0</h4>
        </b-col>
      </b-row>

      <b-row class="row-data">
        <b-col md="4">
          <h4 class="detail">Is pickup on time</h4>
        </b-col>
        <b-col md="8" class="text-left">
          <h4 class="">{{ getIsPickupOnTime(booking) }}</h4>
        </b-col>
      </b-row>

      <b-row class="row-data">
        <b-col md="4">
          <h4 class="detail">Waiting time(hh:mm:ss)</h4>
        </b-col>
        <b-col md="8" class="text-left">
          <h4 class="">{{ booking.total_waiting_time }}</h4>
        </b-col>
      </b-row>

      <b-row class="row-data">
        <b-col md="4">
          <h4 class="detail">Driver</h4>
        </b-col>
        <b-col md="8" class="text-left">
          <h4 class="">
            {{
              booking.driver
              ? booking.driver.name + " " + booking.driver.lastname
              : "Not assigned"
            }}
          </h4>
        </b-col>
      </b-row>

      <b-row class="row-data" v-if="booking.charge_id != null">
        <b-col md="4">
          <h4 class="detail">Price</h4>
        </b-col>
        <b-col md="8" class="text-left">
          <h4 class="">${{ booking.price }}</h4> <a :href="'https://dashboard.stripe.com/payments/' + booking.charge_id"
            target="_blank"> View Stripe Payment
          </a>
          <h5>ChargeId: {{ booking.charge_id }}</h5>
        </b-col>
      </b-row>

      <b-row class="row-data" v-if="booking.service_type != null">
        <b-col md="4">
          <h4 class="detail">Service Type</h4>
        </b-col>
        <b-col md="8" class="text-left">
          <h4 class="">{{ booking.service_type }}</h4>
        </b-col>
      </b-row>
      <b-row class="row-data">
        <b-col md="4">
          <h4 class="detail">Pickup from house address</h4>
        </b-col>
        <b-col md="8" class="text-left">
          <h4 class="">{{ booking.pick_up_location == null ? "No specified" : booking.pick_up_location }}</h4>
        </b-col>
      </b-row>
      <b-row class="row-data">
        <b-col md="4">
          <h4 class="detail">Pickup Address</h4>
        </b-col>
        <b-col md="8" class="text-left">
          <h4 class="">{{ parseLocationFrom(booking.from) }}</h4>
        </b-col>
      </b-row>
      <b-row class="row-data">
        <b-col md="4">
          <h4 class="detail">Destination</h4>
        </b-col>
        <b-col md="8" class="text-left">
          <h4 class="">{{ parseLocationTo(booking.to) }}</h4>
        </b-col>
      </b-row>
      <b-row class="row-data">
        <b-col md="4">
          <h4 class="detail">Surgery Type</h4>
        </b-col>
        <b-col md="8" class="text-left">
          <h4 class="">{{ booking.surgery_type }}</h4>
        </b-col>
      </b-row>
      <b-row class="row-data">
        <b-col md="4">
          <h4 class="detail">Appointment Date</h4>
        </b-col>
        <b-col md="8" class="text-left">
          <h4 class="">{{ convertDate(getAppoimentDate(booking.appoinment_datetime)) }}</h4>
        </b-col>
      </b-row>
      <b-row class="row-data" v-if="booking.is_walkin==0">
        <b-col md="4">
          <h4 class="detail">Appointment Time</h4>
        </b-col>
        <b-col md="8" class="text-left">
          <h4 class="">{{ convertTime2(getAppoimentTime(booking.appoinment_datetime)) }}</h4>
        </b-col>
      </b-row>
      <b-row class="row-data">
        <b-col md="4">
          <h4 class="detail">Suggested Pickup Time</h4>
        </b-col>
        <b-col md="8" class="text-left">
          <h4 class="">{{ convertTime2(booking.pickup_time) }}</h4>
        </b-col>
      </b-row>
      <b-row class="invoice-heading" id="booking_notes" v-if="$route.name == 'details-booking-notes'">
        <b-col md="12">
          <h3 class="detail">Notes Details</h3>
        </b-col>
      </b-row>
      <b-row v-if="$route.name == 'details-booking-notes'">
        <b-col md="12 text-right mb-1">
          <b-button v-b-modal="'bv-modal-example'" class="btn btn-primary">Add New Note</b-button>
        </b-col>
      </b-row>

      <div v-if="showBillingNotes==true && hasPermissionInternalfinancialreports()" class="mt-3">
        <template v-if="booking.id">
      <BillingNotes :bookingId="booking.id" :statusBooking="booking.status"></BillingNotes>
    </template>

      </div>

      <div style="overflow: scroll;">
        <table v-if="booking.Bookinglogs != '' && $route.name == 'details-booking-notes'" class="table">
          <thead class="thead-dark">
            <tr>
              <th scope="col">User</th>
              <th scope="col">Comment</th>
              <th scope="col">Note type</th>
              <th scope="col">Status</th>
              <th scope="col">Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(log, index) in booking.Bookinglogs" :key="index">
              <template v-if="log.type_log == 'note' && log.type_note != 'Sms' && log.type_note != 'billing'">
                <td>   {{ log.username }}</td>

                <td>{{ getComment(log.comments)[0] }}</br><small v-if="getComment(log.comments)[1]">&nbsp; This note was
                    sent
                    to {{ getComment(log.comments)[1] }} via SMS </small></td>
                <td>{{ log.type_note }}</td>
                <td style="min-width: 300px;">{{ log.status }}</td>
                <td style="min-width: 200px;">{{ log.datetime_log }}</td>
                <td>
                  <b-dropdown variant="link" v-if="hasPermissionEditBookingNote || hasPermissionEditBookingNote" no-caret
                    :right="$store.state.appConfig.isRTL" transition="scale-transition" :offset-y="true">
                    <template #button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                    </template>
                    <template v-slot:activator="{ on, attrs }">
                      <b-btn color="primary" v-bind="attrs" v-on="on" icon ripple>
                      </b-btn>
                    </template>
                    <b-list-group v-if="hasPermissionEditBookingNote" style="padding: 0px; margin-bottom: 0px" dense
                      rounded>
                      <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                        <b-list-group-item class="font-weight-bold" @click="openModalEditNote(log)"
                          style="border: none; padding: 0px; color: #7367f0">
                          <feather-icon icon="CheckIcon" />Edit
                        </b-list-group-item>
                      </b-list-group-item>
                    </b-list-group>
                    <b-list-group v-if="hasPermissionDeleteBookingNote" style="padding: 0px; margin-bottom: 0px" dense
                      rounded>
                      <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                        <b-list-group-item class="font-weight-bold" style="border: none; padding: 0px; color: #7367f0"
                          @click="deleteNote(log.id)">
                          <feather-icon icon="CheckIcon" />Delete
                        </b-list-group-item>
                      </b-list-group-item>
                    </b-list-group>
                    <!--End Timer -->
                  </b-dropdown>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
    </b-card>



    <b-modal size="lg" id="bv-modal-example" centered hide-footer>
      <div class="d-block text-center">
        <h3>Write a Note</h3>
        <b-row>
          <b-col>
            <b-form-group>
              <b-form-textarea v-model="messageText" @mouseover="changeColorMessage()"
                v-bind:class="{ 'border border-danger': isMessageText == false }" rows="6" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <div style="display:flex" class="mb-1">
            <b-form-radio class="ml-1" name="radios-type-note" v-model="typeNote" value="Internal note">Internal
              note</b-form-radio>
            <b-form-radio class="ml-1 mr-1" name="radios-type-note" v-model="typeNote" value="Note for driver">Note for
              driver</b-form-radio>
            <b-form-radio class="ml-1 mr-1" name="radios-type-note" v-model="typeNote" value="Note for client">Note for
              client</b-form-radio>
              <b-form-radio class="ml-1 mr-1" name="radios-type-note" v-model="typeNote" value="Note for billing">Note
            for
            billing</b-form-radio>
          </div>
        </b-row>
        <b-row>
          <b-col cols="12" md="9">
            <v-select v-model="selectedDescriptionNote" transition="" label="title" :options="optionsNotes"
              class="select-size-sm" />
          </b-col>
          <b-col cols="12" md="3">
            <b-button size="sm" @click="addDescriptionNote">Add</b-button>
          </b-col>
        </b-row>
      </div>
      <b-row class="mt-3">
        <b-col>
          <b-button block @click="saveNote(typeNote, 'note')">Save</b-button>
        </b-col>
        <b-col>
          <b-button block @click="$bvModal.hide('bv-modal-example')">Close</b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="modal-prices" cancel-only centered size="lg" title="Change Prices">
      <BookingEditPrices :bookingId="id"></BookingEditPrices>
      <template #modal-footer>
        <div class="w-100">
        </div>
      </template>
    </b-modal>
    <b-modal ref="modal-crud-notes" id="modal-crud-notes" cancel-only centered size="md" title="Edit note">
      <b-form-group label="Comment">
        <b-form-textarea v-model="note.comments" rows="4" />
      </b-form-group>
      <b-row>
        <div style="display:flex" class="mb-1">
          <b-form-radio class="ml-1" name="radios-type-note" v-model="note.type_note" value="Internal note">Internal
            note</b-form-radio>
          <b-form-radio class="ml-1 mr-1" name="radios-type-note" v-model="note.type_note" value="Note for driver">Note
            for
            driver</b-form-radio>
          <b-form-radio class="ml-1 mr-1" name="radios-type-note" v-model="note.type_note" value="Note for client">Note
            for
            client</b-form-radio>
            <b-form-radio class="ml-1 mr-1" name="radios-type-note" v-model="note.type_note" value="Note for billing">Note
            for
            billing</b-form-radio>
        </div>
      </b-row>
      <b-row>
        <b-col cols="12 text-right">
          <b-button class="btn btn-primary" @click="updateNote">
            Save
          </b-button>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100">
        </div>
      </template>
    </b-modal>



  </div>
</template>
    
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { getMessageError } from "@core/utils/utils";
import { toFixed } from "@core/utils/numbers_utils.js";
import moment from "moment";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormRadio,
  BFormSelect,
  BFormDatepicker,
  BFormTimepicker,
  BFormCheckbox,
  BButton,
  BModal,
  BDropdown,
  BListGroup,
  BListGroupItem,
  BCard,
} from "bootstrap-vue";
import BookingEditPrices from "@core/components/bookings/BookingEditPrices.vue";
import { convertToMMDDYYYY, convertToHHMM, convertToHHMM2, addTimes, timeToSecs, secsToTime } from "@core/utils/dates";
import { getMenuNotes } from "@core/utils/menus";
import BillingNotes from "@core/components/billing/BillingNotes.vue";

export default {
  components: {
    BookingEditPrices,
    BillingNotes,
    BButton,
    BFormCheckbox,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormRadio,
    BFormSelect,
    BModal,
    BFormDatepicker,
    BFormTimepicker,
    vSelect,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    moment,
    BDropdown,
    BListGroup,
    BListGroupItem,
    BCard,
  },
  data() {
    return {
      messageSendTo: [],
      id: "",
      messageText: "",
      isMessageText: true,
      booking: {
        alternative_number_passenger: null,
        charge_id: null
      },
      enableControl: false,
      listAdditionalStops: [],
      waitingTime: 0,
      chargePayment: {
        receipt_url: "",
        paid: null,
      },
      selectedDescriptionNote: {},
      optionsNotes: [],
      typeNote: "Internal note",
      note: {
        id: 0,
        comments: "",
        type_note: "",
      },
      showBillingNotes:false,
    };
  },
  methods: {
    getComment(comment) {
      return comment.split("array");
      // return comment;
    },
    changeColorMessage() {
      this.isMessageText = true;
    },
    saveNote(typeNote, typelog) {
      if (this.messageText === "") {
        this.isMessageText = false;
      } else {
        this.$bvModal.hide("bv-modal-example");

        this.booking.alternative_number_driver =
          this.booking.alternative_number_driver == null
            ? ""
            : this.booking.alternative_number_driver;
        this.booking.alternative_number_passenger =
          this.booking.alternative_number_passenger == null
            ? ""
            : this.booking.alternative_number_passenger;

        this.$swal({
          title: "Please, wait...",
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        let formData = new FormData();
        formData.append("text", this.messageText);
        formData.append("messageSendTo", this.messageSendTo);
        formData.append("status", this.booking.status);
        formData.append("type_log", typelog);
        formData.append("type_note", typeNote);
        formData.append("alternative_number_driver", this.booking.alternative_number_driver);
        formData.append("alternative_number_passenger", this.booking.alternative_number_passenger);
        formData.append("datetime_log", moment().format("YYYY-MM-DD hh:mm A"));

        this.$http
          .post(`/admin/panel/booking/${this.id}/saveBookingNote`, formData)
          .then((res) => {
            this.$swal({
              title: res.data.message,
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            }).then((result) => {
              this.messageText = "";
              this.getBookingById(this.id);
            });
          })
          .catch((error) => {
            this.$swal({
              title: getMessageError(error),
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      }
    },
    updateNote() {
      this.$bvModal.hide("modal-crud-notes");
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      let formData = new FormData();
      formData.append("id", this.note.id);
      formData.append("comments", this.note.comments);
      formData.append("type_note", this.note.type_note);

      this.$http
        .post(
          `/admin/panel/booking/edit_booking_note`,
          formData
        ).then((res) => {
          this.$swal({
            title: res.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {

          });
        }).catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    deleteNote(id) {
      this.$swal({
        title: "Do you want delete this record?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        buttonsStyling: true,
      }).then((result) => {
        if (result.value === true) {
          this.$swal({
            title: "Please, wait...",
            didOpen: () => {
              this.$swal.showLoading();
            },
          });
          this.$http
            .post(`/admin/panel/delete_booking_note/${id}`)
            .then((res) => {
              this.booking.Bookinglogs = this.booking.Bookinglogs.filter((item) => item.id !== id);
              this.$swal({
                title: res.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              this.$swal({
                title: getMessageError(error),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    getBookingById(bookingId) {
       let chargeId = this.$route.params.charge_id;
       let amount = this.$route.params.amount;

      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$http
        .get(`admin/panel/booking/${bookingId}/info`)
        .then((response) => {
          this.booking = response.data.data;
          if (chargeId != null) {
            this.booking.charge_id=chargeId;
          }
          if (amount != null) {
            this.booking.price=amount;
          }


          this.getaditionalStops();
          this.getInfoCharge(this.booking.charge_id);
          this.$swal.close();
        })
        .catch((error) => {
          let message = getMessageError(error);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    getWaitTime() {
      if (this.booking.additional_service) {
        this.waitingTime = this.booking.additional_service.find((element) =>
          JSON.parse(element.service.toLowerCase().includes("wait"))
        );
      }
    },
    getaditionalStops() {
      if (this.booking.additional_service) {
        this.listAdditionalStops = this.booking.additional_service.filter(
          (element) =>
            JSON.parse(element.service.toLowerCase().includes("stop"))
        );
      }
    },
    getIsPickupOnTime(booking) {
      let res = "I prefer to call Amera when ready";
      if (booking.is_pickup_ontime) {
        res =
          booking.is_pickup_ontime == 0
            ? "I prefer to call Amera when ready"
            : "Amera will pick me up at specified time";
      }
      return res;
    },
    openUpdateBooking() {
      this.$router.push({
        name: "details-reservation",
        params: { id: this.booking.id },
      });
    },
    scroll() {
      document.getElementById("booking_notes").scrollIntoView({
        behavior: "smooth",
      });
    },
    getInfoCharge(charge_id) {
      if (charge_id == null)
        return false;
      this.$http
        .get(`admin/panel/charges/${charge_id}/show`)
        .then((response) => {
          if (response.data.data) {
            this.chargePayment =
              response.data.data === undefined
                ? null
                : response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    retrieveCharge(chargeId) {
      this.$http
        .post(`panel/booking/retrieve_charge/${chargeId}`)
        .then((response) => {
          console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    parseLocationFrom(data) {
      let res = "";

      try {
        res = JSON.parse(data).from;
      } catch (error) {

      }
      return res;
    },
    parseLocationTo(data) {
      let res = "";

      try {
        res = JSON.parse(data).to;
      } catch (error) {

      }
      return res;
    },
    getAppoimentDate(data) {
      let res = "";

      try {
        res = data.split(" ")[0];
      } catch (error) {

      }
      return res;
    },
    getAppoimentTime(data) {
      let res = "";

      try {
        res = data.split(" ")[1];
        //res=convertToHHMM2(res)
      } catch (error) {

      }
      return res;
    },
    convertTime2(data) {
      return convertToHHMM2(data);
    },
    convertDate(data) {
      return convertToMMDDYYYY(data);
    },
    addDescriptionNote() {
      this.messageText += this.selectedDescriptionNote.value + "\n";
      this.selectedDescriptionNote = null;
    },
    loadMenuOptionNote() {
      this.optionsNotes = getMenuNotes();
    },
    openModalEditNote(item) {
      this.note = item;
      this.$bvModal.show("modal-crud-notes");
    },
    hasPermissionEditBookingNote() {
      return this.$hasPermission('edit-booking-note');
    },
    hasPermissionDeleteBookingNote() {
      return this.$hasPermission('delete-booking-note');
    },
    hasPermissionInternalfinancialreports() {
      return this.$hasPermission('internal-financial-reports');
    },
  },
  beforeMount() {
    this.id = this.$route.query.id;
    let bookid = this.$route.query.id;

    if (bookid == null) {
      this.$router.go(-1);
    } else {
      this.getBookingById(bookid);
    }
  },
  mounted() {
    this.loadMenuOptionNote();
    if (this.$route.name == "details-booking-notes") {
      setTimeout(() => {
        this.scroll();
      }, 2000);
    }
    if (this.$route.name == "details-invoice") {
      this.showBillingNotes=true;
    }
    
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>